import './src/styles/enquiry-form.css';
import './src/styles/global.css';

export const onInitialClientRender = () => {
    document.documentElement.lang = 'en';
    const searchParams = new URLSearchParams(window.location.search);
    const utmSource = searchParams.get('utm_source') || '';
    const utmMedium = searchParams.get('utm_medium') || '';
    const utmCampaign = searchParams.get('utm_campaign') || '';
    const utmTerm = searchParams.get('utm_term') || '';
    const utmContent = searchParams.get('utm_content') || '';
    const trafficSource = searchParams.get('traffic_source') || '';
    const organic = searchParams.get('organic') || '';
    const referrer = searchParams.get('referrer') || '';

    // Store UTM parameters and other parameters in localStorage
    if (utmSource || utmMedium || utmCampaign || utmTerm || utmContent || trafficSource || organic || referrer) {
        if (utmSource) localStorage.setItem('utm_source', utmSource);
        if (utmMedium) localStorage.setItem('utm_medium', utmMedium);
        if (utmCampaign) localStorage.setItem('utm_campaign', utmCampaign);
        if (utmTerm) localStorage.setItem('utm_term', utmTerm);
        if (utmContent) localStorage.setItem('utm_content', utmContent);
        if (trafficSource) localStorage.setItem('traffic_source', trafficSource);
        if (organic) localStorage.setItem('organic', organic);
        if (referrer) localStorage.setItem('referrer', referrer);
    }
};
